<template>
  <div class="row">
    <div class="col-12">

      <table class="table table-striped table-hover tx-white">
        <thead class="thead-colored thead-primary">
            <tr>
                <th>Name</th>
                <th>Template</th>
                <th>
                    <button class="btn btn-sm btn-success float-right" @click="showAddModal">Add</button>
                </th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="template of templates">
                <td>{{ template.name }}</td>
                <td>{{ template.template }}</td>
                <td>
                    <button class="btn btn-sm btn-primary" @click="showEditModal(template)">Edit</button>
                    <button class="btn btn-sm btn-danger" @click="deleteDetails(template)">Delete</button>
                </td>
            </tr>
        </tbody>
      </table>

      <div id="addTemplateModal" class="modal fade" style="display: none; padding-right: 17px;">
        <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
            <div class="modal-content bg-dark tx-white tx-size-sm">
                <div class="modal-header bg-dark pd-x-20">
                    <h6 class="tx-14 mg-b-0 tx-uppercase tx-white tx-bold">Add Template</h6>
                    <button type="button" class="close tx-white" data-bs-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </div>
                <div class="modal-body bg-dark tx-white pd-20">

                    <div class="form-group">
                        <label for="name">Name</label>
                        <input type="text" v-model="template.name" class="form-control form-control-dark" />
                    </div>

                    <div class="form-group">
                      <p>You can use any of the following placeholders to automatically insert values into the template:</p>
                      <ul>
                        <li v-html="`{{reference}} - Reference number e.g AD10100000001`"></li>
                        <li v-html="`{{incident_date}} - Incident date e.g 11/10/2024`"></li>
                        <li v-html="`{{incident_time}} - Incident time e.g 11:15:00`"></li>
                        <li v-html="`{{maximum_stay}} - Maximum stay duration e.g 1 Hour 30 Minutes`"></li>
                        <li v-html="`{{stay_duration}} - Actual stay duration e.g 1 Hour 47 Minutes`"></li>
                      </ul>
                    </div>

                    <div class="form-group">
                        <label for="template">Template</label>
                        <textarea rows="5" v-model="template.template" class="form-control form-control-dark" />
                    </div>

                    <button v-if="!template.id" class="btn btn-primary float-right" @click="postDetails">Create</button>
                    <button v-if="template.id" class="btn btn-primary float-right" @click="patchDetails">Update</button>
                    
                </div><!-- modal-body -->
                <div class="modal-footer bg-dark tx-white">
                    <button type="button" class="btn btn-secondary tx-size-xs" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div><!-- modal-dialog -->
      </div>

    </div>
  </div>
</template>

<script>
import axios from 'axios';
import authHeader from '@/services/auth-header';

export default {
  name: 'Templates',
  data(){
    return {
      templates: [],
      template: {
        name: '',
        template: ''
      }
    }
  },
  mounted(){
    this.getDetails();
  },
  methods: {
    getDetails(){
        axios.get(`https://api.varsanpr.com/api/appeals/templates`, {
            headers: authHeader()
        })
        .then(response => {
            this.templates = response.data.data;
        })
        .catch(error => {
            this.$error("Failed fetching template.", error);
            console.error(error);
        });
    },
    deleteDetails(details){
        axios.delete(`https://api.varsanpr.com/api/appeals/templates`, {
            data: {
                id: details.id
            },
            headers: authHeader()
        })
        .then(response => {
            this.$success("Successfully deleted template.");
            this.getDetails();
        })
        .catch(error => {
            this.$error("Failed deleting template.", error);
            console.error(error);
        });
    },
    postDetails(){
        axios.post(`https://api.varsanpr.com/api/appeals/templates`, this.template, {
            headers: authHeader()
        })
        .then(response => {
            this.$success("Successfully added template.");
            this.getDetails();
            this.template = {
                name: "",
                template: ""
            }
            $('#addTemplateModal').modal('toggle');
        })
        .catch(error => {
            this.$error("Failed adding template.", error);
            console.error(error);
        });
    },
    patchDetails(){
        axios.patch(`https://api.varsanpr.com/api/appeals/templates`, this.template, {
            headers: authHeader()
        })
        .then(response => {
            this.$success("Successfully updated template.");
            this.getDetails();
            this.template = {
                name: "",
                template: ""
            }
            $('#addTemplateModal').modal('toggle');
        })
        .catch(error => {
            this.$error("Failed updating template.", error);
            console.error(error);
        });
    },  
    showAddModal(){
        $('#addTemplateModal').modal('toggle');
    },
    showEditModal(details){
        this.template.name = details.name;
        this.template.template = details.template;
        this.template.id = details.id;
        $('#addTemplateModal').modal('toggle');
    }
  }
}

</script>